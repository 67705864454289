/* styles.css */
.gradient-custom {
    background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
      linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
      linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
      radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
      radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
    background-blend-mode: screen, color-dodge, overlay, difference, normal;
  }
  
  .min-vh-100 {
    min-height: 100vh;
  }
  .icon-google-pay {
    color: #4285F4;
    margin: 0 10px;
  }
  
  .icon-btc {
    color: #F7931A;
    margin: 0 10px;
  }
  
  .icon-credit-card {
    color: #0070BA;
    margin: 0 10px;
  }
  
  .icon-apple-pay {
    color: #000000;
    margin: 0 10px;
  }
  
  .icon-cc-paypal {
    color: #003087;
    margin: 0 10px;
  }
  
  .icon-amazon-pay {
    color: #FF9900;
    margin: 0 10px;
  }
  
  /* styles.css */
.icon-large {
    font-size: 2.6em; /* Adjust the size as needed */
    margin: 0 10px;
  }


/* Custom styles for the upload button */
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .upload-btn {
    border: 2px solid #007bff;
    color: #007bff; /* Text color */
    background-color: transparent; /* Transparent background */
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }
  
  .upload-btn:hover {
    background-color: #007bff; /* Background color on hover */
    color: white; /* Text color on hover */
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  
  .payment-method button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.payment-method button.active {
    background-color: #4CAF50; /* Green */
    color: white;
}

.payment-method button:not(.active) {
    background-color: #f1f1f1;
    color: black;
}
.logo-pay img {
  width: 90px;
}
.logo-pay button {
  border: 1px solid #ccc;
  border-radius: 8px;
  background: transparent;
  padding: 5px 10px;
}

.logo-pay button.active {
  background: #f7f7f7;
}

@media only screen and (max-width: 768px) {
  .logo-pay img {
    width: 25px;
  }
  .payment-method button{padding: 10px 9px;}
}